export default {
    init() {
        // Fancybox configuration
        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        // Slick Slider fix
        if ($('.slick-slider').length)
            $('.slick-slider').css('opacity', '1');

        // Scroll to section
        $('.scroll-to-section').click(function () {
            var targetElement = $(this).attr('data-scroll-target');
            if (targetElement) {
                $('html, body').animate({
                    scrollTop: $('#' + targetElement).offset().top - 60,
                }, 'slow');
            }
        });

        sliderInit();
        function sliderInit() {
            // product slider
            $('.product__slider').slick({
                dots: false,
                infinite: true,
                speed: 500,
                fade: false,
                cssEase: 'linear',
                autoplay: false,
                slidesToShow: 5,
                swipeToSlide: true,
                nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                ],
            });

            // Team slider
            $('.team__slider').slick({
                dots: false,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: false,
                slidesToShow: 1,
                nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
                prevArrow: '<button class="slick-prev slick-arrow" aria-label="Prev" type="button"></button>',
            });
        }

        // Some fixes as per design
        sectionLeftSpacing();
        function sectionLeftSpacing() {
            if ($('.product__wrapper').length) {
                if ($(window).width() < 576)
                    $('.product__wrapper').attr('style', 'margin-left:' + $('.footer__top').offset().left + 'px;' + ';width:' + (($(window).width() - $('.footer__top').offset().left) + ($('.product__item').width() * 1)) + 'px');
                else
                    $('.product__wrapper').attr('style', 'margin-left:' + $('.footer__top').offset().left + 'px;' + ';width:' + (($(window).width() - $('.footer__top').offset().left) + ($('.product__item').width() * 0.5)) + 'px');
            }

            if ($('.product__wrapper--mask').length)
                $('.product__wrapper--mask').attr('style', 'width:' + ($('.footer__top').offset().left - 25) + 'px;');

            // if ($('.header__logo').length)
            //     $('.header__logo').attr('style', 'padding-left:' + $('.footer__top').offset().left + 'px;');
        }

        $(window).resize(function () {
            sectionLeftSpacing();
        });

        $('.product__wrapper .slick-next').on('click', function () {
            $('.product__wrapper').addClass('product__wrapper--showall');
        });

        // Slick swipe event
        $('.product__slider').on('swipe', function () {
            $('.product__wrapper').addClass('product__wrapper--showall');
        });

        // Comment icon fix when it over the banner
        commentFix();
        function commentFix() {
            if ($('.banner').length && ($('.banner').outerHeight() < $('.floating__icon').offset().top))
                $('.floating__icon').removeClass('floating__icon--white');
            else
                $('.floating__icon').addClass('floating__icon--white');
        }
        $(document).scroll(function () {
            commentFix();
        });

        setTimeout(function () {
            sectionLeftSpacing();
            commentFix();
        }, 1000);

        setTimeout(function () {
            $('.block__content').matchHeight();
        }, 300);

        $('.faq__filter .searchandfilter > ul > li li').addClass('checkcontainer').append('<span class="checkmark"></span>');

        $('.datepicker').attr('autocomplete', 'off');
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
