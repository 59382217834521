import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat)

/* eslint-disable */
import * as modules0 from './modules/header.js'; import * as modules1 from './modules/singleProducten.js'; import * as modules2 from './modules/templateContact.js'; import * as modules3 from './modules/templateFaq.js'; import * as modules4 from './modules/templateTeam.js'; let modules = [modules0, modules1, modules2, modules3, modules4]
const now = dayjs();
const workDayFrom = dayjs(now.format('YYYY-MM-DD')+'T'+params.maandag_tm_vrijdag_vanaf +':00')
const workDayTo = dayjs(now.format('YYYY-MM-DD')+'T'+params.maandag_tm_vrijdag_tot +':00')
const sundayFrom = dayjs(now.format('YYYY-MM-DD')+'T'+params.zaterdag_vanaf +':00')
const sundayTo = dayjs(now.format('YYYY-MM-DD')+'T'+params.zaterdag_tot +':00')

let open = false;
const { __} = wp.i18n;
//Check if is workday and if store is currently open
if(now.day() > 0 && now.day() < 6 && now > workDayFrom && now < workDayTo) {

    open = true;
} else if(now.day() === 6 && now > sundayFrom && now < sundayTo){
    //Today is saturday and store is opened
    open = true;
}

if (params.vandaag_gesloten === 'Ja') {
    open = false;
}

const element = $('#available');

if(open){
    element.find('span').addClass('user__status--online')
    element.append(__('Nu bereikbaar!', 'sage'));
} else {
    element.find('span').addClass('user__status--offline')
    element.append(__('Gesloten', 'sage'));
}
